import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
0% {
    opacity: 0;
} 
  100% {
    opacity: 1;
  }
`;

export const PlayerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
  position: relative;
  background: white;

  @media screen and (min-width: 768px) {
    height: 100vh;
  }
`;

export const PlaceHolder = styled.div`
  animation: ${fadeIn} 3s;
`;
