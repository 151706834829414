import styled, { keyframes, css } from 'styled-components';
import { navy, green } from '../../components/core/colours.styles';
import { spacing32, spacing40 } from '../../components/core/spacing.styles';
import { rem } from '../../components/core/styles';

type StayInTheKnowProps = {
  show?: boolean;
};

const easeIn = keyframes`
  0% {
    opacity: 0;
  }
  10% {
  opacity: 1;

  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;

  }
  100% {
    opacity: 0;
  }
`;

export const StayInTheKnowContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const StayInTheKnowContent = styled.div`
  display: flex;
  max-width: 1160px;
  margin: 0 auto;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding: ${rem(150)} ${rem(24)} ${rem(86)} ${rem(24)};

  @media screen and (min-width: 768px) {
    flex-direction: row;
    padding: ${rem(150)} ${rem(140)} ${rem(96)} ${rem(140)};
  }
`;

export const FormWrapper = styled.div`
  max-width: 560px;
  /* margin-left: auto; */

  /* @media screen and (min-width: 1116px) {
    position: relative;
    top: ${rem(-96)};
  } */
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  > *:first-child {
    margin: 0 0 ${rem(spacing40)} 0;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    > *:first-child {
      margin: 0 ${rem(spacing40)} 0 0;
    }
  }
`;

export const AltFormRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  > *:first-child {
    margin: 0 0 ${rem(spacing32)} 0;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const animate = css`
  ${easeIn} 2s linear;
`;

export const Popup = styled.div<StayInTheKnowProps>`
  animation: ${({ show }) => (show ? animate : 'none')};
  color: ${navy};
  background-color: ${green};
  opacity: 0;
  padding: 12px 0;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
`;
