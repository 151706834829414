import styled from 'styled-components';
import { rem } from '../../components/core/styles';

export const HeroContainer = styled.div`
  width: 100%;
  height: 100vh;
`;
// TODO: Add padding into each child div in hero image on mobile version

export const HeroImage = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  @media screen and (min-width: 768px) {
    height: 80vh;
  }
`;

export const HeroContentContainer = styled.div`
  padding: 0 24px;
`;

export const HeroDescription = styled.div`
  max-width: ${rem(510)};
`;
