import { useState } from 'react';
import ReactPlayer from 'react-player/youtube';
import { useParallax } from 'react-scroll-parallax';
import { PlayerContainer, PlaceHolder } from './player.styles';
import PlayButton from '../button/play-button';
import useMediaQuery from '../../utils/useMediaQuery';
import Youtube from '../../images/youtube.svg';
import MobileVideo from '../../images/mobile-video.svg';

const Player = () => {
  const isDesktop = useMediaQuery('(min-width: 768px)');
  const [play, setPlay] = useState(false);
  const playerParallax = useParallax<HTMLDivElement>({
    translateY: [-60, 0, 'easeOutQuad'],
    scale: [0.8, 1, 'easeOutQuint'],
    disabled: !isDesktop
  });

  const url = play
    ? 'https://www.youtube.com/embed/YXH6nTB3c18?autoplay=1'
    : 'https://www.youtube.com/embed/YXH6nTB3c18';

  const [defaultUrl, setDefaultUrl] = useState(url);

  return (
    <PlayerContainer>
      <PlaceHolder
        ref={playerParallax.ref}
        style={{ width: '100%', position: 'relative', display: 'flex' }}
      >
        {isDesktop ? (
          <ReactPlayer
            url={defaultUrl}
            playIcon={<PlayButton />}
            playing={play}
            style={{ position: 'relative', margin: '0 auto' }}
            light={Youtube}
            width="1300px"
            height="620px"
            config={{
              playerVars: { modestbranding: 1 }
            }}
            onClickPreview={() => setPlay(true)}
            onEnded={() =>
              setDefaultUrl('https://www.youtube.com/embed/YXH6nTB3c18')
            }
          />
        ) : (
          <ReactPlayer
            url={defaultUrl}
            playing={play}
            style={{ position: 'relative', margin: '0 auto' }}
            light={MobileVideo}
            config={{
              playerVars: { modestbranding: 1 }
            }}
            onClickPreview={() => setPlay(true)}
            onEnded={() =>
              setDefaultUrl('https://www.youtube.com/embed/YXH6nTB3c18')
            }
          />
        )}
      </PlaceHolder>
    </PlayerContainer>
  );
};

export default Player;
