import Banner from '../../components/banner/banner';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import Intro from '../../components/Intro/Intro';
import WordCarousel from '../../components/wordcarousel/wordcarousel';
import Player from '../../components/player/player';
import { Layout } from '../../components/layout/layout.styles';
import Hero from '../hero/hero';
import StayInTheKnow from '../StayInTheKnow/StayInTheKnow';
import { HomeContentWrapper, HomeIntroWrapper } from './Home.styles';
import { useLayoutEffect, useState } from 'react';

const Home = () => {
  const [bgOpacity, setBgOpacity] = useState('0');
  useLayoutEffect(() => {
    const checkpoint = 500;
    const onScroll = () => {
      let opacity;
      const currentScroll = window.pageYOffset;
      if (currentScroll <= checkpoint) {
        opacity = 0 + currentScroll / checkpoint;
      } else {
        opacity = 1;
      }
      setBgOpacity(`${opacity}`);
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);
  return (
    <Layout>
      <Header />
      <HomeContentWrapper>
        <HomeIntroWrapper opacity={bgOpacity}>
          <Intro />
          <Hero />
        </HomeIntroWrapper>
        <Player />
        <StayInTheKnow />
        <Banner />
        <WordCarousel />
      </HomeContentWrapper>
      <Footer />
    </Layout>
  );
};

export default Home;
