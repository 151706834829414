import styled from 'styled-components';
import { spacing40 } from '../core/spacing.styles';
import { rem } from '../core/styles';

type StackProps = {
  spacing?: number;
  direction: 'vertical' | 'horizontal';
};

export const Stack = styled.div<StackProps>`
  > *:not(:last-child) {
    ${({ direction, spacing }) =>
      direction === 'vertical' &&
      `
      margin-bottom: ${rem(spacing ? spacing : spacing40)};
    `}

    ${({ direction, spacing }) =>
      direction === 'horizontal' &&
      `
      margin-right: ${rem(spacing ? spacing : spacing40)};
    `}
  }

  > *:last-child {
    ${({ direction }) =>
      direction === 'vertical' &&
      `
        margin-bottom: 0;
      `}

    ${({ direction }) =>
      direction === 'horizontal' &&
      `
        margin-right: 0;
      `}
  }
`;
