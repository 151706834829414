import styled from 'styled-components';
import { green, navy, white } from '../core/colours.styles';
import { rem } from '../core/styles';
import {
  fontSize16,
  fontSize16LineHeight,
  gtPlanarLight,
  gtPlanarRegular
} from '../core/typography.styles';

type ButtonProps = {
  $width?: number;
};
export const Button = styled.button<ButtonProps>`
  padding: ${rem(10)} 0;
  background: ${green};
  border: none;
  border-radius: ${rem(48)};
  font-family: ${gtPlanarRegular};
  font-size: ${fontSize16};
  line-height: ${fontSize16LineHeight};
  color: ${navy};
  height: ${rem(56)};
  width: ${({ $width = 0 }) => rem($width)};
  white-space: nowrap;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
`;

export const SecondaryButton = styled.button<ButtonProps>`
  padding: ${rem(10)} ${rem(32)} ${rem(10)} ${rem(32)};
  background: transparent;
  border: 2px solid ${white};
  border-radius: ${rem(48)};
  font-family: ${gtPlanarLight};
  font-size: ${fontSize16};
  line-height: ${fontSize16LineHeight};
  color: ${white};
  height: ${rem(56)};
  width: ${({ $width = 0 }) => rem($width)};

  &:hover {
    cursor: pointer;
  }
`;

export const PlayButton = styled.button`
  height: ${rem(115)};
  width: ${rem(115)};
  border: none;
  background: ${green};
  border-radius: 50%;

  &:hover {
    cursor: pointer;
  }

  img {
    height: 32px;
    width: 32px;
  }
`;
