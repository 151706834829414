import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import useMediaQuery from '../../utils/useMediaQuery';
import { teal } from '../core/colours.styles';
import { Heading70, Heading160 } from '../core/typography.styles';
import Day1 from '../../images/day1.png';
import Day2 from '../../images/day2.png';
import Day3 from '../../images/day3.png';
import Day4 from '../../images/day4.png';
import Day5 from '../../images/day5.png';
import MobileDay1 from '../../images/mobile-day1.jpeg';
import MobileDay2 from '../../images/mobile-day2.png';
import MobileDay3 from '../../images/mobile-day3.jpeg';
import MobileDay4 from '../../images/mobile-day4.jpeg';
import MobileDay5 from '../../images/mobile-day5.jpeg';

import {
  IntroContainer,
  IntroImage,
  IntroImageMask,
  IntroImageMaskMobile,
  IntroText,
  IntroTextFirst,
  IntroTextSecond,
  IntroTextThird
} from './Intro.styles';

const Intro = () => {
  const isDesktop = useMediaQuery('(min-width: 768px)');
  const [day, setDay] = useState(5);
  useEffect(() => {
    const getDay = () => {
      const releaseDate = dayjs('2022-10-11');
      const timeLeft = releaseDate.diff(dayjs(new Date()), 'milliseconds');

      const day = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
      if (day <= 0) {
        clearInterval(countdown);
        setDay(1);
      } else {
        setDay(day);
      }
    };

    const countdown = setInterval(getDay, 1000);
    getDay();
  }, []);
  return (
    <IntroContainer>
      <IntroImage>
        {isDesktop ? (
          <IntroImageMask>
            {day === 1 && (
              <img
                src={Day1}
                style={{ width: '100%', height: '100vh' }}
                alt="Day 1"
              />
            )}
            {day === 2 && (
              <img
                src={Day2}
                style={{ width: '100%', height: '100vh' }}
                alt="Day 2"
              />
            )}
            {day === 3 && (
              <img
                src={Day3}
                style={{ width: '100%', height: '100vh' }}
                alt="Day 3"
              />
            )}
            {day === 4 && (
              <img
                src={Day4}
                style={{ width: '100%', height: '100vh' }}
                alt="Day 4"
              />
            )}
            {day >= 5 && (
              <img
                src={Day5}
                style={{ width: '100%', height: '100vh' }}
                alt="Day 5"
              />
            )}
          </IntroImageMask>
        ) : (
          <IntroImageMaskMobile>
            {day === 1 && (
              <img
                src={MobileDay1}
                style={{ width: '100%', height: '100vh', objectFit: 'cover' }}
                alt="Day 1"
              />
            )}
            {day === 2 && (
              <img
                src={MobileDay2}
                style={{ width: '100%', height: '100vh', objectFit: 'cover' }}
                alt="Day 2"
              />
            )}
            {day === 3 && (
              <img
                src={MobileDay3}
                style={{ width: '100%', height: '100vh', objectFit: 'cover' }}
                alt="Day 3"
              />
            )}
            {day === 4 && (
              <img
                src={MobileDay4}
                style={{ width: '100%', height: '100vh', objectFit: 'cover' }}
                alt="Day 4"
              />
            )}
            {day >= 5 && (
              <img
                src={MobileDay5}
                style={{ width: '100%', height: '100vh', objectFit: 'cover' }}
                alt="Day 5"
              />
            )}
          </IntroImageMaskMobile>
        )}
        <IntroText>
          {isDesktop ? (
            <Heading160 isHeavy colour={teal} style={{ display: 'flex' }}>
              <IntroTextFirst>{day === 1 ? 'DAY' : 'DAYS'}</IntroTextFirst>
              <IntroTextSecond>TO</IntroTextSecond>
              <IntroTextThird>GO</IntroTextThird>
            </Heading160>
          ) : (
            <Heading70 isHeavy colour={teal} style={{}}>
              {day === 1 ? 'DAY' : 'DAYS'} TO GO.
            </Heading70>
          )}
        </IntroText>
      </IntroImage>
    </IntroContainer>
  );
};

export default Intro;
