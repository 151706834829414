import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import useMediaQuery from '../../utils/useMediaQuery';
import {
  CountdownContainer,
  CountdownGroup,
  CountdownNumber,
  CountdownText
} from './countdown.styles';

const Countdown = () => {
  const isDesktop = useMediaQuery('(min-width: 768px)');
  const [time, setTime] = useState<{
    days?: number;
    hours?: number;
    minutes?: number;
    seconds?: number;
  }>({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    const calcTimeDiff = () => {
      const releaseDate = dayjs('2022-10-11');
      const timeLeft = releaseDate.diff(dayjs(new Date()), 'milliseconds');

      const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

      if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
        clearInterval(countdown);
        setTime(
          // `${days} DAYS\xA0\xA0${hours} HOURS\xA0\xA0${minutes} MINS\xA0\xA0${seconds} SECS`
          { days: 0, hours: 0, minutes: 0, seconds: 0 }
        );
      } else {
        setTime(
          // `${days} DAYS\xA0\xA0${hours} HOURS\xA0\xA0${minutes} MINS\xA0\xA0${seconds} SECS`
          { days, hours, minutes, seconds }
        );
      }
    };

    const countdown = setInterval(calcTimeDiff, 1000);
    calcTimeDiff();
  }, []);

  const { days, hours, minutes, seconds } = time;

  const width = isDesktop ? '120px' : '60px';
  const paddingLeft = isDesktop ? '25px' : '8px';
  return (
    <CountdownContainer>
      <CountdownGroup style={{ color: '#84FFE5', width }}>
        <CountdownNumber>
          {days?.toString().length === 1 ? `0${days}` : days}
        </CountdownNumber>
        <CountdownText>DAYS</CountdownText>
      </CountdownGroup>
      <CountdownGroup style={{ color: '#fff', width, paddingLeft }}>
        <CountdownNumber>
          {hours?.toString().length === 1 ? `0${hours}` : hours}
        </CountdownNumber>
        <CountdownText>HOURS</CountdownText>
      </CountdownGroup>
      <CountdownGroup style={{ color: '#fff', width, paddingLeft }}>
        <CountdownNumber>
          {minutes?.toString().length === 1 ? `0${minutes}` : minutes}
        </CountdownNumber>
        <CountdownText>MINS</CountdownText>
      </CountdownGroup>
      <CountdownGroup style={{ color: '#fff', width, paddingLeft }}>
        <CountdownNumber>
          {seconds?.toString().length === 1 ? `0${seconds}` : seconds}
        </CountdownNumber>
        <CountdownText>SECS</CountdownText>
      </CountdownGroup>
    </CountdownContainer>
  );
};

export default Countdown;
