import styled, { keyframes } from 'styled-components';

const slideIn = keyframes`
  from {
    opacity: 0;
      transform: translateY(100%);
  }

  to {
    opacity: 1;
      transform: translateY(0);
  }
`;

const scaleMask = keyframes`
  from {
    mask-size: 360vmax 360vmax;
    mask-position: 50% 50%;
  }

  to {
    mask-size: 180vmax 180vmax;
    mask-position: 80% 60%;
  }
`;

export const IntroContainer = styled.div`
  height: 700px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: center;
`;

export const IntroImageMask = styled.div`
  mask-image: url(./B-vector.svg);
  mask-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation: 1.5s 0s 1 ${scaleMask} ease-out both;
  z-index: -1;

  //allocate all remaining space to image
  flex: 1;
`;

export const IntroImageMaskMobile = styled.div`
  mask-image: url(./B.svg);
  mask-repeat: no-repeat;
  mask-size: 110vmax 110vmax;
  mask-position: 70% 120%;
  position: absolute;
  top: -200px;
  left: 0;
  z-index: -1;
`;

export const IntroImage = styled.div`
  height: 100%;
  width: 100%;
  z-index: 1;

  @media screen and (min-width: 768px) {
    background: none;
    height: 100vh;
    //allocate all remaining space to image
    flex: 1;
  }
`;

export const IntroText = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100vh;
  & > h1 {
    margin-bottom: 45%;
  }

  @media screen and (min-width: 768px) {
    height: 100vh;
    margin-left: 30%;
    align-items: center;

    & > h1 {
      margin-bottom: 0;
    }
  }
  z-index: 1;
`;

export const IntroTextFirst = styled.div`
  @media screen and (min-width: 768px) {
    animation: 1s 1s 1 ${slideIn} both;
  }
`;

export const IntroTextSecond = styled.div`
  @media screen and (min-width: 768px) {
    padding-left: 30px;
    animation: 1s 1.1s 1 ${slideIn} both;
  }
`;

export const IntroTextThird = styled.div`
  @media screen and (min-width: 768px) {
    padding-left: 30px;
    animation: 1s 1.2s 1 ${slideIn} both;
  }
`;
