import { useParallax } from 'react-scroll-parallax';
import {
  HeroContainer,
  HeroImage,
  HeroContentContainer,
  HeroDescription
} from './hero.styles';
import {
  fontSize20,
  fontSize24,
  fontSize32LineHeight,
  Heading52,
  Heading80,
  PStyled
} from '../../components/core/typography.styles';
import { teal, white } from '../../components/core/colours.styles';
import useMediaQuery from '../../utils/useMediaQuery';
import { spacing8 } from '../../components/core/spacing.styles';
import Countdown from '../../components/countdown/countdown';

const Hero = () => {
  const isDesktop = useMediaQuery('(min-width: 768px)');
  const counterParallax = useParallax<HTMLDivElement>({
    easing: 'easeInQuart',
    translateY: [0, -300],
    disabled: !isDesktop
  });
  const textParallax = useParallax<HTMLDivElement>({
    speed: 65,
    disabled: !isDesktop
  });

  return (
    <HeroContainer>
      <HeroImage id="hero">
        <div ref={counterParallax.ref}>
          <Countdown />
        </div>
        <HeroContentContainer ref={textParallax.ref}>
          {isDesktop ? (
            <div>
              <Heading80 isHeavy colour={white}>
                A NEW BETTING
              </Heading80>
              <Heading80 isHeavy colour={white}>
                BRAND FOR
              </Heading80>
              <Heading80 isHeavy colour={white}>
                SPORTS FANS
              </Heading80>
              <Heading80 isHeavy colour={teal} marginBottom={spacing8}>
                IS COMING.
              </Heading80>
            </div>
          ) : (
            <div>
              <Heading52 isHeavy colour={white}>
                A NEW BETTING
              </Heading52>
              <Heading52 isHeavy colour={white}>
                BRAND FOR
              </Heading52>
              <Heading52 isHeavy colour={white}>
                SPORTS FANS
              </Heading52>
              <Heading52 isHeavy colour={teal} marginBottom={spacing8}>
                IS COMING.
              </Heading52>
            </div>
          )}
          <HeroDescription>
            <PStyled
              isLight
              colour={white}
              fontSize={isDesktop ? fontSize20 : fontSize24}
              lineHeight={fontSize32LineHeight}
            >
              Attention sports fans. A new betting brand made for you kicks off
              soon. With exclusive markets, promotions, insights and content.
              That’s betr.
            </PStyled>
          </HeroDescription>
        </HeroContentContainer>
      </HeroImage>
    </HeroContainer>
  );
};

export default Hero;
