import * as Styled from './button.styles';
import PlayIcon from '../../images/play-line.svg';

const PlayButton = () => {
  return (
    <Styled.PlayButton>
      <img src={PlayIcon} alt="play" />
    </Styled.PlayButton>
  );
};

export default PlayButton;
