import styled, { keyframes } from 'styled-components';
import { blue, teal } from '../core/colours.styles';
import { spacing24 } from '../core/spacing.styles';
import { rem } from '../core/styles';
import {
  dezenHeavy,
  fontSize26,
  fontSize26LineHeight,
  fontWeight800
} from '../core/typography.styles';

export const BannerContainer = styled.div``;

const scroll = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
  `;

export const ScrollContainer = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  background-color: ${teal};
  padding-top: ${rem(21)};
  padding-bottom: ${rem(10)};
`;

export const ScrollTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
  animation: ${scroll} 9s linear infinite;
`;

export const ScrollText = styled.span`
  max-width: 100%;
  color: ${blue};
  margin-left: ${rem(spacing24)};
  margin-right: ${rem(spacing24)};
  white-space: nowrap;
  font-size: ${fontSize26};
  line-height: ${fontSize26LineHeight};
  font-family: ${dezenHeavy};
  font-weight: ${fontWeight800};
  margin-bottom: 0;
`;
