import {
  WordCarouselContainer,
  WordCarouselText,
  WordCarouselTextAnimate,
  WordInner,
  WordOuter,
  ImageWrapper
} from './wordcarousel.styles';

const WordCarousel = () => (
  <WordCarouselContainer>
    <WordCarouselText>
      <span>BETR</span>
      <WordOuter>
        <WordInner>
          <WordCarouselTextAnimate>sports</WordCarouselTextAnimate>
          <WordCarouselTextAnimate>data</WordCarouselTextAnimate>
          <WordCarouselTextAnimate>insights</WordCarouselTextAnimate>
          <WordCarouselTextAnimate>content</WordCarouselTextAnimate>
          <WordCarouselTextAnimate>sports</WordCarouselTextAnimate>
        </WordInner>
      </WordOuter>
    </WordCarouselText>
    <ImageWrapper>
      <img src={'./iPhone.svg'} alt="Betr Logo" style={{ width: '100%' }} />
    </ImageWrapper>
  </WordCarouselContainer>
);

export default WordCarousel;
