import styled from 'styled-components';
import { rem } from '../../components/core/styles';

type HomeIntroWrapperProps = {
  opacity: string;
};

export const HomeContentWrapper = styled.div`
  position: relative;
  top: ${rem(-56)};
`;

export const HomeIntroWrapper = styled.section<HomeIntroWrapperProps>`
  background: linear-gradient(180deg, #093ad3 0%, #01166f 100%), #ffffff;
  position: relative;

  @media screen and (min-width: 768px) {
    &:before {
      position: absolute;
      top: 0px;
      bottom: 0px;
      right: 0px;
      left: 0px;
      content: ' ';
      background: url('./b-outline.png') no-repeat fixed;
      opacity: ${props => props.opacity || '0'};
    }
  }
`;
