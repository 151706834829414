import { sports } from './banner.constants';
import {
  ScrollTextWrapper,
  ScrollContainer,
  ScrollText
} from './banner.styles';

const Banner = () => {
  return (
    <ScrollContainer>
      <ScrollTextWrapper>
        {sports.map(sport => (
          <ScrollText key={`${sport}1`}>{sport}</ScrollText>
        ))}
      </ScrollTextWrapper>
      <ScrollTextWrapper>
        {sports.map(sport => (
          <ScrollText key={`${sport}2`}>{sport}</ScrollText>
        ))}
      </ScrollTextWrapper>
      <ScrollTextWrapper>
        {sports.map(sport => (
          <ScrollText key={`${sport}3`}>{sport}</ScrollText>
        ))}
      </ScrollTextWrapper>
    </ScrollContainer>
  );
};

export default Banner;
