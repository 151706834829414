import styled from 'styled-components';
import { navy } from '../../components/core/colours.styles';
import { spacing128, spacing24 } from '../../components/core/spacing.styles';
import { rem } from '../../components/core/styles';

export const PrivacyPolicyContainer = styled.div`
  background-color: ${navy};
  width: 100%;
  margin: 0 auto;
  position: relative;
  top: ${rem(-56)};
`;

export const PrivacyPolicyContent = styled.div`
  padding: ${rem(spacing128)} ${rem(spacing24)} ${rem(spacing128)};
  max-width: 1280px;
  margin: 0 auto;
  @media screen and (min-width: 768px) {
    padding: ${rem(180)} ${rem(80)} ${rem(spacing128)};
  }
`;
