import styled, { css } from 'styled-components';
import { blue, navy, red } from '../core/colours.styles';
import { rem } from '../core/styles';
import {
  altfontSize16LineHeight,
  fontSize14,
  fontSize14LineHeight,
  fontSize16,
  gtPlanarLight,
  gtPlanarRegular
} from '../core/typography.styles';
import { spacing40 } from '../core/spacing.styles';
import Checked from '../../images/checked.svg';

type InputProps = {
  isError?: boolean;
};
export const formStyles = css<InputProps>`
  width: 100%;
  background-color: transparent;
  color: ${navy};
  font-family: ${gtPlanarRegular};
  font-size: ${fontSize16};
  line-height: ${altfontSize16LineHeight};
  border: 1px solid ${navy};
  box-sizing: border-box;
  padding: ${rem(20)} ${rem(30)};
  height: ${rem(57)};

  &:focus {
    border: 2px solid ${blue};
    outline: 0;
  }

  &::placeholder {
    color: ${navy};
  }

  ${({ isError }) =>
    isError &&
    `
      border: 2px solid ${red};
    `}
`;

type errorProps = {
  top?: string;
};
export const ErrorMsg = styled.div<errorProps>`
  font-family: ${gtPlanarLight};
  font-size: ${fontSize14};
  line-height: ${fontSize14LineHeight};
  color: ${red};
  position: absolute;
  top: ${({ top }) => top};
`;

export const TextInput = styled.input.attrs(() => ({ type: 'text' }))`
  ${formStyles}
`;

export const EmailInput = styled.input.attrs(() => ({ type: 'email' }))`
  ${formStyles}
`;

export const DateInput = styled.input.attrs(() => ({ type: 'date' }))`
  ${formStyles}
`;

export const Checkbox = styled.input.attrs(() => ({ type: 'checkbox' }))`
  /* appearance: none; */
  border: 1px solid ${navy};
  height: ${rem(22)};
  width: ${rem(22)};
  background-color: transparent;
  position: relative;
  accent-color: #093ad3;
  &:focus {
    border: 2px solid ${blue};
  }
  /* svg {
    fill: ${navy};
  }
  &:checked::after {
    top: -0.12rem;
    left: -0.12rem;
    position: absolute;
    content: url(${Checked});
    height: ${rem(22)};
    width: ${rem(22)};
  } */
`;

export const InputLabel = styled.label`
  padding-left: ${rem(spacing40)};
  display: block;
  font-family: ${gtPlanarLight};
  font-size: ${fontSize16};
  margin-top: ${rem(-30)};
  line-height: ${altfontSize16LineHeight};
  color: ${navy};
`;

// for accessibility purposes
export const OffscreenInputLabel = styled.label`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

export const FormGroup = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`;
