import styled, { css } from 'styled-components';
import { rem } from './styles';

export const dezenRegular = `Dezen Pro Regular`;
export const dezenHeavy = `Dezen Pro Heavy`;
export const gtPlanarRegular = `GT Planar Regular`;
export const gtPlanarLight = `GT Planar Light`;

export const fontWeight300 = 300;
export const fontWeight400 = 400;
export const fontWeight800 = 800;

export const fontSize12 = rem(12);
export const fontSize14 = rem(14);
export const fontSize16 = rem(16);
export const fontSize20 = rem(20);
export const fontSize24 = rem(24);
export const fontSize26 = rem(26);
export const fontSize32 = rem(32);
export const fontSize52 = rem(52);
export const fontSize68 = rem(68);
export const fontSize70 = rem(70);
export const fontSize80 = rem(80);
export const fontSize90 = rem(90);
export const fontSize94 = rem(94);
export const fontSize130 = rem(130);
export const fontSize160 = rem(160);

export const fontSize12LineHeight = rem(18);
export const fontSize14LineHeight = rem(18);
export const altfontSize16LineHeight = rem(18);
export const fontSize16LineHeight = rem(24);
export const fontSize20LineHeight = rem(24);
export const fontSize24LineHeight = rem(24);
export const fontSize26LineHeight = rem(26);
export const fontSize32LineHeight = rem(36);
export const fontSize52LineHeight = rem(50);
export const fontSize68LineHeight = rem(68);
export const fontSize70LineHeight = rem(70);
export const fontSize80LineHeight = rem(80);
export const fontSize90LineHeight = rem(78);
export const fontSize94LineHeight = rem(94);
export const fontSize130LineHeight = rem(130);
export const fontSize160LineHeight = rem(160);

type textAlignment = 'left' | 'center' | 'right';

type HeadingProps = {
  isHeavy?: boolean;
  marginBottom?: number;
  textAlign?: textAlignment;
  colour?: string;
};
const baseHeading = css<HeadingProps>`
  font-family: ${({ isHeavy }) => (isHeavy ? dezenHeavy : dezenRegular)};
  font-weight: ${fontWeight800};
  margin: 0 0
    ${({ marginBottom }) =>
      marginBottom !== undefined ? rem(marginBottom) : 0}
    0;
  ${({ textAlign }) =>
    textAlign &&
    `
    display: block;
    text-align: ${textAlign};
    width: 100%;
  `}
  color: ${({ colour }) => colour};
`;

export const Heading160 = styled.h1<HeadingProps>`
  font-size: ${fontSize160};
  line-height: ${fontSize160LineHeight};
  ${baseHeading}
`;

export const Heading130 = styled.h1<HeadingProps>`
  font-size: ${fontSize130};
  line-height: ${fontSize130LineHeight};
  ${baseHeading}
`;

export const Heading94 = styled.h1<HeadingProps>`
  font-size: ${fontSize94};
  line-height: ${fontSize94LineHeight};
  ${baseHeading}
`;

export const Heading90 = styled.h1<HeadingProps>`
  font-size: ${fontSize90};
  line-height: ${fontSize90LineHeight};
  ${baseHeading}
`;

export const Heading80 = styled.h1<HeadingProps>`
  font-size: ${fontSize80};
  line-height: ${fontSize80LineHeight};
  ${baseHeading}
`;

export const Heading70 = styled.h1<HeadingProps>`
  font-size: ${fontSize70};
  line-height: ${fontSize70LineHeight};
  ${baseHeading}
`;

export const Heading68 = styled.h1<HeadingProps>`
  font-size: ${fontSize68};
  line-height: ${fontSize68LineHeight};
  ${baseHeading}
`;

export const Heading52 = styled.h2<HeadingProps>`
  font-size: ${fontSize52};
  line-height: ${fontSize52LineHeight};
  ${baseHeading}
`;

export const Heading24 = styled.h2<HeadingProps>`
  font-size: ${fontSize24};
  line-height: ${fontSize24LineHeight};
  ${baseHeading}
`;

type PStyledProps = {
  isLight?: boolean;
  marginBottom?: number;
  textAlign?: textAlignment;
  colour?: string;
  fontSize?: string;
  lineHeight?: string;
  letterSpacing?: string;
};
export const PStyled = styled.p<PStyledProps>`
  display: block;
  font-family: ${({ isLight }) => (isLight ? gtPlanarLight : gtPlanarRegular)};
  font-size: ${({ fontSize = fontSize24 }) => fontSize};
  line-height: ${({ lineHeight = fontSize24LineHeight }) => lineHeight};
  margin: 0 0 ${({ marginBottom = 0 }) => rem(marginBottom)} 0;
  color: ${({ colour }) => colour};
  font-weight: ${fontWeight300};
  letter-spacing: ${({ letterSpacing }) => letterSpacing};
  ${({ textAlign }) =>
    textAlign &&
    `
    display: block;
    text-align: ${textAlign};
    width: 100%;
  `}
`;

export const LinkStyled = styled.a<PStyledProps>`
  font-family: ${({ isLight }) => (isLight ? gtPlanarLight : gtPlanarRegular)};
  font-size: ${({ fontSize = rem(16) }) => fontSize};
  line-height: ${({ lineHeight = rem(25) }) => lineHeight};
  letter-spacing: 0.04em;
  color: ${({ colour }) => colour};
  cursor: pointer;
  @media screen and (min-width: 768px) {
    text-align: right;
  }
`;
