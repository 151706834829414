import { useForm } from 'react-hook-form';
import { Button } from '../../components/button/button.styles';
import { navy } from '../../components/core/colours.styles';
import { spacing16 } from '../../components/core/spacing.styles';
import {
  fontSize14,
  fontSize14LineHeight,
  LinkStyled,
  PStyled
} from '../../components/core/typography.styles';
import { ErrorMessage } from '../../components/error/error.styles';
import {
  Checkbox,
  DateInput,
  EmailInput,
  FormGroup,
  InputLabel,
  OffscreenInputLabel,
  TextInput
} from '../../components/input/input.styles';
import { Stack } from '../../components/stack/stack.styles';
import {
  AltFormRow,
  FormRow,
  FormWrapper,
  Popup
} from './StayInTheKnow.styles';
import { FormData } from './StayInTheKnow.constants';
import { useState } from 'react';
import { rem } from '../../components/core/styles';
import useMediaQuery from '../../utils/useMediaQuery';
import axios from 'axios';

const StayInTheKnowForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<FormData>();

  const isDesktop = useMediaQuery('(min-width: 768px)');

  const [isDateInvalid, setIsDateInvalid] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const validateAge = (value: string) => {
    setIsDateInvalid(false);
    const today = new Date();
    const birthDate = new Date(value);
    if (!birthDate.getTime()) {
      setIsDateInvalid(true);
      return false;
    }
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    if (age < 18) return false;

    return true;
  };

  const onSubmit = handleSubmit(formData => {
    console.log(formData);
    const data = {
      FirstName: formData.firstName || ' ',
      LastName: formData.lastName || ' ',
      Email: formData.email || ' ',
      DOB: formData.dateOfBirth || ' ',
      ConsentGiven: formData.is18 || false
    };

    axios
      .post(
        'https://sheet.best/api/sheets/7bae3813-6288-450c-88ec-64f1acccddc6',
        data
      )
      .then(res => {
        reset();
        setIsSuccess(true);
        console.log(res);
      });
  });
  return (
    <FormWrapper>
      <form onSubmit={onSubmit}>
        <Stack direction="vertical">
          <FormRow>
            <FormGroup>
              <OffscreenInputLabel htmlFor="firstName">
                First name
              </OffscreenInputLabel>
              <TextInput
                id="firstName"
                placeholder="First name"
                isError={!!errors.firstName}
                {...register('firstName', { required: 'Enter a first name.' })}
              />
              <ErrorMessage error={errors.firstName?.message} />
            </FormGroup>
            <FormGroup>
              <OffscreenInputLabel htmlFor="lastName">
                Last name
              </OffscreenInputLabel>
              <TextInput
                id="lastName"
                placeholder="Last name"
                isError={!!errors.lastName}
                {...register('lastName', { required: 'Enter a last name.' })}
              />
              <ErrorMessage error={errors.lastName?.message} />
            </FormGroup>
          </FormRow>
          <FormGroup>
            <OffscreenInputLabel htmlFor="dateOfBirth">
              Date of birth (DD/MM/YY)
            </OffscreenInputLabel>
            <DateInput
              id="dateOfBirth"
              placeholder="Date of birth (DD/MM/YY)"
              isError={!!errors.dateOfBirth}
              {...register('dateOfBirth', {
                required: true,
                valueAsDate: true,
                validate: validateAge
              })}
            />
            {errors.dateOfBirth?.type === 'validate' && (
              <ErrorMessage
                error={
                  isDateInvalid
                    ? 'Invalid Date Entered'
                    : 'You must be over 18 to register.'
                }
              />
            )}
          </FormGroup>
          <FormGroup>
            <OffscreenInputLabel htmlFor="email">Email</OffscreenInputLabel>
            <EmailInput
              id="email"
              placeholder="Email"
              isError={!!errors.email}
              {...register('email', {
                required: 'You must enter an email address',
                pattern:
                  /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ /* eslint-disable-line */
              })}
            />
            {errors.email ? (
              <>
                {errors.email?.type === 'required' && (
                  <ErrorMessage error={errors.email.message} />
                )}
                {errors.email?.type === 'pattern' && (
                  <ErrorMessage error="The email entered is invalid." />
                )}
              </>
            ) : null}
          </FormGroup>
          <Stack spacing={spacing16} direction="vertical">
            <div>
              <Checkbox id="is18" {...register('is18', { required: true })} />
              <InputLabel htmlFor="is18">
                I confirm that I am over 18 and consent to receive direct
                marketing communications from betr.
              </InputLabel>
            </div>
            <AltFormRow>
              <div style={{ maxWidth: isDesktop ? rem(380) : rem(560) }}>
                <PStyled
                  isLight
                  colour={navy}
                  fontSize={fontSize14}
                  lineHeight={fontSize14LineHeight}
                >
                  For information on how we collect, handle and use your
                  personal information please see our{' '}
                  <LinkStyled
                    href="/privacy-policy"
                    colour={navy}
                    fontSize={fontSize14}
                    lineHeight={fontSize14LineHeight}
                  >
                    privacy policy
                  </LinkStyled>
                  .
                </PStyled>
              </div>
              <Button $width={144}>Submit</Button>
            </AltFormRow>
          </Stack>
        </Stack>
      </form>
      <Popup show={isSuccess} onAnimationEnd={() => setIsSuccess(false)}>
        Successfully Submitted
      </Popup>
    </FormWrapper>
  );
};

export default StayInTheKnowForm;
