import styled from 'styled-components';

export const Layout = styled.main`
  width: 100%;
  margin: 0 auto;
`;

export const ContentWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  height: calc(100vh + 1rem);
  @media screen and (min-width: 769px) {
  }
`;
