import styled from 'styled-components';
import { rem } from '../core/styles';
import { dezenHeavy, fontWeight800 } from '../core/typography.styles';

export const CountdownText = styled.span`
  font-family: ${dezenHeavy};
  font-size: ${rem(11.55)};
  font-weight: ${fontWeight800};

  @media screen and (min-width: 768px) {
    font-size: ${rem(24.09)};
  }
`;

export const CountdownNumber = styled.span`
  font-family: ${dezenHeavy};
  font-size: ${rem(56)};
  font-weight: ${fontWeight800};

  @media screen and (min-width: 768px) {
    font-size: ${rem(116.8)};
  }
`;

export const CountdownGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 768px) {
    padding: 10px;
  }
`;

export const CountdownContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  height: 150px;

  @media screen and (min-width: 768px) {
    padding: 10px;
    height: 100%;
  }
`;
