import {
  AStyled,
  FooterContainer,
  FooterContent,
  FooterContentBlock,
  FooterText,
  Logo,
  SocialIcon
} from './footer.styles';
import {
  PStyled,
  fontSize16,
  fontSize16LineHeight,
  fontSize12
} from '../core/typography.styles';
import { white } from '../core/colours.styles';
import Logo1 from '../../images/logo.svg';
import {
  spacing16,
  spacing24,
  spacing40,
  spacing64
} from '../core/spacing.styles';
import useMediaQuery from '../../utils/useMediaQuery';
import Facebook from '../../images/social-facebook.svg';
import Twitter from '../../images/social-twitter.svg';
import Instagram from '../../images/social-instagram.svg';
import { Stack } from '../stack/stack.styles';

const Footer = () => {
  const isDesktop = useMediaQuery('(min-width: 1025px)');
  return (
    <FooterContainer>
      <FooterContent>
        <FooterContentBlock flex={1}>
          <Logo src={Logo1} />
          <FooterContentBlock
            flexDirection="row"
            marginBottom={spacing40}
            spaceBetween={spacing64}
          >
            <a
              href="https://www.facebook.com/betrcomau-100374239466278 "
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIcon src={Facebook} />
            </a>
            <a
              href="https://twitter.com/betr_au"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIcon src={Twitter} />
            </a>
            <a
              href="https://www.instagram.com/betr.com.au/ "
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIcon src={Instagram} />
            </a>
          </FooterContentBlock>
          {isDesktop && (
            <PStyled
              colour={white}
              fontSize={fontSize12}
              letterSpacing="0.04em"
            >
              © betr.com.au 2022
            </PStyled>
          )}
        </FooterContentBlock>
        <FooterContentBlock flex={1}>
          <Stack direction="vertical" spacing={16.75}>
            <PStyled
              isLight={true}
              colour={white}
              letterSpacing="0.02em"
              fontSize={fontSize16}
              lineHeight={fontSize16LineHeight}
            >
              <AStyled href="/privacy-policy">Privacy Policy</AStyled>
            </PStyled>
            <FooterText
              colour={white}
              isLight={true}
              fontSize={fontSize16}
              marginBottom={isDesktop ? spacing24 : spacing16}
              lineHeight={fontSize16LineHeight}
              letterSpacing="0.02em"
            >
              18+. You know the score. Stay in control. Gamble Responsibly.
            </FooterText>
            <FooterText
              colour={white}
              isLight={true}
              fontSize={fontSize16}
              marginBottom={spacing24}
              lineHeight={fontSize16LineHeight}
              letterSpacing="0.02em"
            >
              Is Gambling a problem for you? Call Gambler’s Help on{' '}
              <AStyled href="tel:1800858858">1800 858 858</AStyled> or visit 
              <AStyled
                href="https://www.gamblershelp.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.gamblershelp.com.au
              </AStyled>
            </FooterText>
            {/* <FooterText
              colour={white}
              isLight={true}
              fontSize={fontSize12}
              marginBottom={isDesktop ? spacing24 : spacing16}
              lineHeight={fontSize16LineHeight}
              letterSpacing="0.02em"
            >
              We are licensed and regulated by the Northern Territory Racing
              Commission
            </FooterText> */}
            <PStyled
              colour={white}
              fontSize={fontSize12}
              letterSpacing="0.04em"
            >
              We are licensed and regulated by the Northern Territory Racing
              Commission
            </PStyled>

            {!isDesktop && (
              <PStyled
                colour={white}
                fontSize={fontSize12}
                letterSpacing="0.04em"
              >
                © betr.com.au 2022
              </PStyled>
            )}
          </Stack>
        </FooterContentBlock>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
