import styled from 'styled-components';
import { red } from '../core/colours.styles';
import { rem } from '../core/styles';
import {
  fontSize14,
  fontSize14LineHeight,
  gtPlanarLight
} from '../core/typography.styles';

const ErrorMessageStyled = styled.div`
  position: absolute;
  color: ${red};
  font-size: ${fontSize14};
  line-height: ${fontSize14LineHeight};
  font-family: ${gtPlanarLight};
  margin-top: ${rem(65)};
`;

type Props = {
  error?: string;
};
export const ErrorMessage = ({ error }: Props) => {
  if (error) return <ErrorMessageStyled>{error}</ErrorMessageStyled>;

  return null;
};
