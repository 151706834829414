import styled, { keyframes } from 'styled-components';
import { spacing24, spacing64 } from '../core/spacing.styles';
import { rem } from '../core/styles';

const slideInFromTop = keyframes`
  from {
    opacity: 0;
      transform: translateY(-100%);
  }

  to {
    opacity: 1;
      transform: translateY(0);
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${rem(spacing24)} 0 ${rem(spacing24)};
  /* margin-top: ${rem(24)}; */

  @media screen and (min-width: 768px) {
    animation: 0.5s 1.7s 1 ${slideInFromTop} both;
    top: ${rem(spacing64)};
    padding: 0 ${rem(80)} 0 ${rem(80)};
  }
`;

export const HeaderWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  position: sticky;
  top: ${rem(24)};
  z-index: 1000;
`;
