import { ParallaxProvider } from 'react-scroll-parallax';
import '../src/components/core/fonts/fonts.css';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import Home from './pages/Home/Home';
import { Route, Routes } from 'react-router-dom';

function App() {
  return (
    <ParallaxProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </ParallaxProvider>
  );
}

export default App;
