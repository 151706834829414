import { HeaderContainer, HeaderWrapper } from './header.styles';
import Logo from '../../images/logo.svg';
import { Button, SecondaryButton } from '../button/button.styles';
import { useNavigate } from 'react-router-dom';

type Props = {
  isPrivacy?: boolean;
};

const Header = ({ isPrivacy }: Props) => {
  const nav = useNavigate();
  const scrollTo = () => {
    const scrollDiv = document.getElementById('stayInTheKnow')?.offsetTop;
    window.scrollTo({
      top: scrollDiv,
      behavior: 'smooth'
    });
  };

  return (
    <HeaderWrapper>
      <HeaderContainer>
        <img src={Logo} alt="Betr Logo" />

        {isPrivacy ? (
          <SecondaryButton
            $width={144}
            onClick={() => {
              nav('/');
            }}
          >
            Home
          </SecondaryButton>
        ) : (
          <Button $width={144} onClick={scrollTo}>
            stay in the know
          </Button>
        )}
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export default Header;
