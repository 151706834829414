import { useParallax } from 'react-scroll-parallax';
import { blue, navy } from '../../components/core/colours.styles';
import { Heading90, Heading70 } from '../../components/core/typography.styles';
import useMediaQuery from '../../utils/useMediaQuery';
import {
  StayInTheKnowContainer,
  StayInTheKnowContent
} from './StayInTheKnow.styles';
import StayInTheKnowForm from './StayInTheKnowForm';

const StayInTheKnow = () => {
  const isDesktop = useMediaQuery('(min-width: 768px)');
  const titleParallax = useParallax<HTMLDivElement>({
    translateY: [0, 100, 'easeInQuad'],
    disabled: !isDesktop
  });

  return (
    <StayInTheKnowContainer id="stayInTheKnow">
      <StayInTheKnowContent>
        <div ref={titleParallax.ref}>
          {isDesktop ? (
            <div>
              <Heading90 isHeavy colour={navy}>
                STAY IN
              </Heading90>
              <Heading90 isHeavy colour={blue}>
                THE KNOW.
              </Heading90>
            </div>
          ) : (
            <div>
              <Heading70 isHeavy colour={navy}>
                STAY IN THE
              </Heading70>
              <Heading70 isHeavy colour={blue} marginBottom={70}>
                KNOW.
              </Heading70>
            </div>
          )}
        </div>
        <StayInTheKnowForm />
      </StayInTheKnowContent>
    </StayInTheKnowContainer>
  );
};

export default StayInTheKnow;
