import styled, { keyframes } from 'styled-components';
import { rem } from '../core/styles';
import { blue } from '../core/colours.styles';
import { dezenHeavy, fontWeight800 } from '../core/typography.styles';

const fontSize = 116.8;
const carousel = keyframes`
	0%, 20% {
		transform: translateY(${fontSize * -6}px);
	} 
    25%, 45% {
		transform: translateY(${fontSize * -4.5}px);
	} 

	50%, 70% {
		transform: translateY(${fontSize * -3}px);
	}
	75%, 95% {
		transform: translateY(${fontSize * -1.5}px);
	}
	100% {
		transform: translateY(0);
	}
`;

export const WordCarouselContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 285px;
  width: 100%;
  background: ${blue};
  z-index: -1;

  @media screen and (min-width: 768px) {
    height: 700px;
  }
`;

export const WordCarouselText = styled.div`
  flex: 1;
  color: white;
  font-family: ${dezenHeavy};
  font-size: ${rem(33.36)};
  font-weight: ${fontWeight800};
  text-transform: uppercase;
  line-height: ${fontSize * 1.5}px;
  height: ${fontSize * 1}px;
  position: relative;
  margin-left: 25px;

  @media screen and (min-width: 768px) {
    font-size: ${rem(fontSize)};
    margin-left: 75px;
    line-height: ${fontSize * 1.5}px;
    height: ${fontSize * 1.5}px;
  }
`;

export const WordCarouselTextAnimate = styled.div`
  display: block;
`;

export const WordOuter = styled.div`
  position: absolute;
  top: 0;
  left: 70px;
  text-align: left;
  height: 100px;
  overflow: hidden;

  @media screen and (min-width: 768px) {
    font-size: ${rem(116.8)};
    margin-left: 75px;
    left: 180px;
    height: ${fontSize * 1.5}px;
  }
`;

export const WordInner = styled.div`
  position: relative;
  animation: ${carousel} 7.5s ease-in-out infinite;
`;

export const ImageWrapper = styled.div`
  display: flex;
`;
