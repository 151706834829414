import { white } from '../../components/core/colours.styles';
import {
  spacing16,
  spacing40,
  spacing64
} from '../../components/core/spacing.styles';
import {
  fontSize16,
  fontSize16LineHeight,
  Heading24,
  Heading94,
  PStyled
} from '../../components/core/typography.styles';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import { Layout } from '../../components/layout/layout.styles';
import {
  PrivacyPolicyContainer,
  PrivacyPolicyContent
} from './PrivacyPolicy.styles';

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Header isPrivacy />
      <PrivacyPolicyContainer>
        <PrivacyPolicyContent>
          <Heading94 colour={white} isHeavy marginBottom={spacing64}>
            Privacy Policy
          </Heading94>
          <Heading24 colour={white} isHeavy marginBottom={spacing16}>
            Heading
          </Heading24>
          <PStyled
            colour={white}
            fontSize={fontSize16}
            lineHeight={fontSize16LineHeight}
            marginBottom={spacing40}
            letterSpacing="0.02em"
            isLight={true}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nec posuere
            at elementum facilisis. A suscipit gravida dignissim consequat
            viverra dolor at eu elit. Fermentum cursus suscipit egestas blandit
            imperdiet suspendisse. Diam odio id mauris ante mauris, pellentesque
            mauris lacus, nullam. Donec pulvinar sit lobortis velit vel, ac
            maecenas. In quis sit eu malesuada faucibus. Sed egestas adipiscing
            quis sit cursus dictum at. Amet morbi quam lectus iaculis adipiscing
            lacus, ipsum neque sollicitudin. Massa pharetra cras aliquet
            imperdiet. At aliquam turpis enim, porttitor. Ullamcorper luctus
            condimentum risus odio vulputate. Sagittis sed feugiat phasellus
            rhoncus mattis etiam mattis. Sed nam eget libero, hendrerit mattis
            cursus maecenas velit.
          </PStyled>
          <Heading24 colour={white} isHeavy marginBottom={spacing16}>
            Heading
          </Heading24>
          <PStyled
            colour={white}
            fontSize={fontSize16}
            lineHeight={fontSize16LineHeight}
            marginBottom={spacing16}
            letterSpacing="0.02em"
            isLight
          >
            Adipiscing dui nulla morbi senectus tincidunt dignissim diam.
            Volutpat ipsum laoreet sed suspendisse ipsum cras. Justo, accumsan
            placerat ipsum gravida. Leo lorem gravida pellentesque accumsan
            auctor. Penatibus mauris est varius posuere pellentesque amet.
            Condimentum enim accumsan aliquam nulla morbi enim. Tellus nunc nec
            velit nisl. In commodo facilisis adipiscing ultricies nec gravida
            pharetra, sollicitudin enim.
          </PStyled>
          <PStyled
            colour={white}
            fontSize={fontSize16}
            lineHeight={fontSize16LineHeight}
            marginBottom={spacing16}
            letterSpacing="0.02em"
            isLight={true}
          >
            Tellus, faucibus lectus elit ipsum. Tempor, sem nisi ipsum,
            dictumst. Nulla tincidunt bibendum eget amet in ut et arcu. Lobortis
            at vitae est egestas amet. Tellus suspendisse nisl, adipiscing nunc,
            ultricies nunc, elit tincidunt sit. Tellus augue sem sit amet
            pharetra. Elementum non non amet massa, cursus eget massa bibendum
            ultricies. Malesuada pretium malesuada massa in nisl, vitae nec.
          </PStyled>
          <PStyled
            colour={white}
            fontSize={fontSize16}
            lineHeight={fontSize16LineHeight}
            marginBottom={spacing40}
            letterSpacing="0.02em"
            isLight={true}
          >
            In urna nulla faucibus condimentum maecenas id tincidunt at. Tortor,
            egestas velit sed placerat lectus curabitur hac. Hac nibh sed a,
            fermentum tincidunt amet, cum. Amet, sed aenean phasellus ultrices.
            Sed platea blandit etiam laoreet. Dui pretium erat at non in blandit
            morbi volutpat. Pharetra cras in feugiat id massa dapibus imperdiet
            tempor ullamcorper. Ac urna, tortor, egestas aliquam purus sagittis
            blandit. Vitae, est odio elit orci. Leo quisque ipsum adipiscing
            mauris accumsan eu. Adipiscing at metus, neque, fermentum. Dignissim
            suscipit dui ullamcorper mauris leo, facilisis. Feugiat sed quis
            est, pellentesque.
          </PStyled>
          <Heading24 colour={white} isHeavy={true} marginBottom={spacing16}>
            Heading
          </Heading24>
          <PStyled
            colour={white}
            fontSize={fontSize16}
            lineHeight={fontSize16LineHeight}
            marginBottom={spacing16}
            letterSpacing="0.02em"
            isLight={true}
          >
            Malesuada lorem ac, proin metus diam, malesuada elementum ultrices.
            Tristique augue diam nunc faucibus arcu condimentum sed viverra. Mi
            velit justo eget lectus at sit amet elit tincidunt. Hendrerit semper
            phasellus fermentum turpis morbi nullam est quisque quam. Sed fames
            nisl, vitae euismod id tincidunt in orci. Tincidunt egestas congue
            sapien faucibus. Cursus pellentesque odio imperdiet viverra ut eu. A
            dictum turpis magna sit elementum dolor quis mi. Scelerisque
            facilisis neque mattis sed lectus. Pharetra ut dolor tincidunt eget
            tempus. Amet risus faucibus amet nisl diam ac imperdiet consectetur.
          </PStyled>
          <PStyled
            colour={white}
            fontSize={fontSize16}
            lineHeight={fontSize16LineHeight}
            marginBottom={spacing16}
            letterSpacing="0.02em"
            isLight={true}
          >
            Volutpat placerat id turpis elementum. Justo, nullam at congue proin
            sapien. Ultricies et, ut vitae nunc, aliquam. Vel semper proin
            faucibus id vel pellentesque tristique aliquet. Vulputate ultrices
            viverra facilisis amet. Facilisis aenean vitae aliquam sed volutpat.
            Volutpat porttitor in congue at nisl pellentesque. Pretium a
            dignissim in fringilla lacus magna tempus tincidunt tellus. Etiam in
            eget cras facilisis scelerisque. Posuere tellus amet libero
            consectetur non malesuada massa. Vitae odio urna nisl, amet massa
            aliquam. Convallis vivamus leo placerat in non eget eget.
          </PStyled>
          <PStyled
            colour={white}
            fontSize={fontSize16}
            lineHeight={fontSize16LineHeight}
            marginBottom={spacing16}
            letterSpacing="0.02em"
            isLight={true}
          >
            Et ligula dolor faucibus pellentesque consequat in purus gravida
            ultrices. Condimentum volutpat in sed faucibus. Ut mi est, nulla nec
            feugiat enim ornare. Urna adipiscing porta nunc eu vel semper
            egestas mattis orci. Scelerisque eu venenatis dignissim enim non,
            egestas. Egestas felis risus egestas semper ac. Faucibus viverra
            aliquam nulla libero. Ultricies accumsan, volutpat massa purus
            feugiat.
          </PStyled>
          <PStyled
            colour={white}
            fontSize={fontSize16}
            lineHeight={fontSize16LineHeight}
            marginBottom={spacing40}
            letterSpacing="0.02em"
            isLight={true}
          >
            Scelerisque nec urna ultrices sed felis, placerat. Parturient ac
            posuere mauris tincidunt ac diam adipiscing elit. Ut adipiscing sed
            lobortis nisi blandit et. Sed turpis ac fermentum, pharetra a arcu
            tempor in. Sed enim, metus interdum dignissim donec consectetur
            laoreet laoreet. Neque, ut commodo aenean rhoncus nunc, quis.
            Malesuada pretium felis leo magna orci. Platea magna donec at sed
            vulputate fringilla habitasse.
          </PStyled>
        </PrivacyPolicyContent>
      </PrivacyPolicyContainer>
      <Footer />
    </Layout>
  );
};

export default PrivacyPolicy;
