import styled from 'styled-components';
import { navy, white } from '../core/colours.styles';
import { rem } from '../core/styles';
import { spacing24, spacing64, spacing40 } from '../core/spacing.styles';
import { PStyled } from '../core/typography.styles';

export const FooterContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${navy};
  margin-top: ${rem(-56)};
  z-index: 1000;
`;

export const FooterContent = styled.div`
  padding: ${rem(55)} ${rem(spacing24)} ${rem(spacing64)};
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (min-width: 769px) {
    flex-direction: row;
    padding: ${rem(55)} ${rem(80)} ${rem(spacing64)};
  }
`;

export const FooterBlock = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    width: 100%;
  }
`;
type FooterContentBlockProps = {
  flex?: number;
  flexDirection?: string;
  marginBottom?: number;
  spaceBetween?: number;
};
export const FooterContentBlock = styled.div<FooterContentBlockProps>`
  display: flex;
  flex-direction: column;
  flex: ${({ flex }) => flex};
  flex-direction: ${({ flexDirection }) => flexDirection};
  margin: 0 0 ${({ marginBottom = 0 }) => rem(marginBottom)} 0;
  & > * {
    margin-right: ${({ spaceBetween = 0 }) => rem(spaceBetween)};
  }
  @media screen and (min-width: 768px) {
    & > * {
      margin-right: ${rem(spacing64)};
    }
  }
`;

export const Logo = styled.img`
  margin-bottom: ${rem(spacing40)};
  max-width: min-content;
  @media screen and (min-width: 769px) {
    margin-bottom: ${rem(spacing64)};
  }
`;

export const SocialIcon = styled.img`
  &:not(:last-child) {
    margin-right: ${rem(spacing64)};
  }
`;

export const AStyled = styled.a`
  color: ${white};
`;

export const FooterText = styled(PStyled)`
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;
